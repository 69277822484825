<template>
  <!--begin::Advance Table Widget 9-->
  <v-card class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <v-card-title class="m-5 ">
      <h3 class="font-weight-bolder text-primary">Table Reservations</h3>
    </v-card-title>
    <!--end::Header-->
    <!--begin::Body-->
    <v-card-subtitle>
      <v-data-table
        :headers="headers"
        :items="form.reservations.data"
        item-key="id"
        class="elevation-1"
        :items-per-page="itemsPerPage"
        :page="currentPage"
        :footer-props="{'items-per-page-options': []}"

      >
            <template v-slot:item.actions="{ item }">
                   <span class="d-flex">
                <template v-if="item.status === 'Pending'">
                  <v-btn @click="openConfirmationDialog(item.id, 'Reserved')" class="mr-1 bg-primary text-light" small>Accept</v-btn>
                  <v-btn @click="openConfirmationDialog(item.id, 'Rejected')" class="bg-danger text-light" small>Decline</v-btn>
                </template>
                <template v-else>
                  <v-btn :class="item.status == 'Reserved' ? 'bg-primary text-light'  : 'bg-danger text-light'" small>
                    {{ item.status }}
                  </v-btn>
                </template>
              </span>
        </template>
      </v-data-table>
    </v-card-subtitle>
    <!-- Pagination Controls -->
    <v-pagination
      v-model="currentPage"
      :length="totalPages"
      :total-visible="7"
      @input="loadReservations"
    ></v-pagination>
    <!--end::Body-->
    <v-dialog v-model="dialog.visible" max-width="400">
      <v-card>
        <v-card-title class="headline">Confirm Action</v-card-title>
        <v-card-subtitle>
          Are you sure you want to change the status to "{{ dialog.status }}"?
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="confirmChangeStatus" class="bg-primary text-light font-poppins" >Yes</v-btn>
          <v-btn @click="dialog.visible = false" text>No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
  <!--end::Advance Table Widget 9-->
</template>
<script>
import { BUSINESS_RESERVATIONS_UPDATE, BUSINESS_RESERVATIONS } from "@/core/services/store/config.module";
import { mapGetters } from "vuex";

export default {
  name: "ReservationsTableWidget",
  computed: {
    ...mapGetters(["currentUser"]),
    totalPages() {
      // Calculate the total number of pages based on total items and items per page
      return Math.ceil(this.form.reservations.total / this.itemsPerPage);
    }
  },
  data() {
    return {
      form: {
        reservations: {
          data: [], // Data array
          current_page: 1,
          last_page: 1,
          total: 0
        },
      },
      headers: [
        { text: 'Booking ID', value: 'id',sortable: false  },
        { text: 'First Name', value: 'first_name',sortable: false  },
        { text: 'Last Name', value: 'last_name',sortable: false  },
        { text: 'Email', value: 'email' ,sortable: false },
        { text: 'Contact', value: 'phone' ,sortable: false },
        { text: 'Date', value: 'date' ,sortable: false },
        { text: 'Number Of People', value: 'number_people' ,sortable: false },
        { text: 'Status', value: 'status',sortable: false  },
        { text: 'Instructions', value: 'instructions',sortable: false  },
        { text: 'Actions', value: 'actions', sortable: false ,sortable: false },
      ],
      dialog: {
        visible: false,
        id: null,
        status: ''
      },
      currentPage: 1,
      itemsPerPage: 10,
      loading: false
    };
  },
  watch: {
    currentPage(newPage) {
      this.loadReservations(newPage);
    }
  },
  mounted() {
    
      this.loadReservations();
  
  },
  methods: {
    loadReservations(page = 1) {
      
      this.loading = true;
      console.log('page is ',page)
      this.$store
        .dispatch(BUSINESS_RESERVATIONS, { page, per_page: this.itemsPerPage })
        .then((response) => {
          this.form.reservations = response;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    openConfirmationDialog(id, status) {
      this.dialog.visible = true;
      this.dialog.id = id;
      this.dialog.status = status;
    },
    confirmChangeStatus() {
      const { id, status } = this.dialog;
      this.dialog.visible = false;

      const data = {
        status: status,
        id: id
      };

      this.$store
        .dispatch(BUSINESS_RESERVATIONS_UPDATE, data)
        .then(() => {
          this.loadReservations(this.currentPage); // Refresh reservations on the same page
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
};
</script>
